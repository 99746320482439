import { ReactElement } from "react";
import { MediaKitEditor } from "~/components/Mediakits/MediaKitEditor";
import { CreatorAppLayout } from "~/layout/CreatorAppLayout";
import { CreatorLayout } from "~/layout/CreatorLayout";
import { MediaKitLayout } from "~/layout/MediaKitLayout";

export type ActiveEditorType =
	| "none"
	| "header"
	| "casting-tags"
	| "about"
	| "platform"
	| "collabs"
	| "rates";

const MediaKits = () => {
	return <MediaKitEditor />;
};

MediaKits.getLayout = (page: ReactElement) => {
	return (
		<CreatorAppLayout>
			<CreatorLayout>
				<MediaKitLayout>{page}</MediaKitLayout>
			</CreatorLayout>
		</CreatorAppLayout>
	);
};

export default MediaKits;
